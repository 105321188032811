import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { spotifyAccessToken } from "../../ajax";

export default class Spotify extends React.Component {
  componentDidMount() {
    const code = new URLSearchParams(window.location.search).get("code");

    spotifyAccessToken(code).then((response) => {
      localStorage.setItem("spotify_access_token", response["access_token"]);
      localStorage.setItem("spotify_refresh_token", response["refresh_token"]);

      window.close();
    });
  }

  render() {
    return (
      <div className="container">
        <span className="icon-text">
          <span className="icon">
            <FontAwesomeIcon icon={faSpinner} spin />
          </span>
          <span className="is-size-7">Authenticating Spotify</span>
        </span>
      </div>
    );
  }
}
